import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  container: {
    marginTop: 27,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    height: 22,
  },
  notHovered: {
    '& path': {
      fill: '#808080',
    },
  }
});

export default useStyles;
