import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const Step = ({ step, title, className, disabled = false, inactive = false }) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.container, className,{
        [classes.inactive]: inactive,
      })}
    >
      <p
        className={classnames(classes.stepTitle, {
          [classes.disabledStepTitle]: disabled,
          [classes.enabledStepTitle]: !disabled,
        })}
      >
        {`STEP ${step}`}
      </p>
      <p className={classes.details}>
        {title}
      </p>
      <div className={classes.progressContainer}>
        <div className={classes.progressGray}/>
        <div className={classes.progressColor} style={{ width: disabled ? '0%' : '100%' }}/>
      </div>
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.number.isRequired,
  inactive: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  title: PropTypes.string,
  display: PropTypes.bool,
  className: PropTypes.string,
};

Step.defaultProps = {
  disabled: false,
  inactive: false,
  className: '',
};

export default Step;
