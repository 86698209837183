import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    
    '& > a': {
      minWidth: 'calc(50% - 10px)',
      marginBottom: '10px',
    }
  },
});
