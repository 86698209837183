import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  '@font-face': {
    fontFamily: 'Circular',
    src: 'url("fonts/CircularXXTT-Regular.ttf") format("ttf")',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  app: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainContent: {
    width: '100vw',
  },
  supportText: {
    width: '100%',
    margin: '0 auto 10px',
    fontSize: '15px',
    color: '#414652',
    textAlign: 'center',
    '& a': {
      color: '#414652',
      textDecoration: 'none',
    },
    '& a:visited': {
      color: '#414652',
    },
  },
  closedFaucetBanner: {
    height: 40,
    backgroundColor: '#28abe2',
    color: 'white',
    fontSize: 17,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > p': {
      margin: 0,
    }
  },
});
