import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    fontFamily: 'Circular',
    fontSize: '16px',
    marginTop: 27,
    padding: '26px 20px 31px',
    background: '#F3F4F4',
    borderRadius: '25px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: '20px',
    fontWeight: 700,
    color: '#292A2E',
    margin: '0 0 9px',
  },
  description: {
    lineHeight: '22px',
    fontWeight: 400,
    color: '#555659',
    margin: '0 0 19px',
  },
  redHeart: {
    margin: '0 5px',
  },
  twitterButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    width: '100%',
    border: 'none',
    outline: 'none',
    padding: '12px',
    boxSizing: 'border-box',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    backgroundColor: '#292A2E',
    borderRadius: '40px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  twitterLogo: {
    height: '20px !important',
    marginRight: '7px',
  },
});
