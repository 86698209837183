import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    marginTop: '20px',
  },
  title: {
    textAlign: 'left',
    margin: '20px 0',
    fontFamily: 'Circular',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '36px',
  },
  githubButton: {
    marginBottom: '25px',
  }
});
