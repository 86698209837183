import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, GradientButton, CodeBox } from 'components';

import { useStyles } from './styles';

const GET_PRINCIPAL_COMMAND = 'dfx identity get-principal';
const DFINITY_CANNISTER_TEXT = ' DFINITY’s Canister SDK ';

const Step2 = ({
  nextStep,
  state,
  setState,
}) => {
  const classes = useStyles();

  const onChange = (e) => {
    setState({
      ...state,
      principalId: e.target.value,
    });
  };
  
  const onClick = () => {
    nextStep();
  };

  return (
    <div>
      <h1 className={classes.title}>
        Enter DFX Principal ID
      </h1>
      <h2 className={classes.inputLabel}>
        DFX Principal ID
      </h2>
      <InputBase onChange={onChange} value={state.principalId} className={classes.input}/>
      <GradientButton
        onClick={onClick}
        disabled={!state.principalId}
        title="Continue"
        className={[classes.gradientButton, state.principalId && 'hvr-scale'].join(' ')}
      />
      <div className={classes.footer}>
        <h3 className={classes.footerSubtitle}>
          Need a Principal ID?
        </h3>
        <p className={classes.footerDescription}>
          Run the command below in the DFX CLI tool available on
          <a
            target="_blank"
            rel="noreferrer"
            href="https://sdk.dfinity.org/docs/index.html"
            className={`${classes.link} ${classes.boldedText}`}
          >
            {DFINITY_CANNISTER_TEXT}
          </a>
          to return your Principal ID.
        </p>
        <CodeBox code={GET_PRINCIPAL_COMMAND} />
      </div>
    </div>
  );
};

Step2.propTypes = {
  nextStep: PropTypes.func.isRequired,
  state: PropTypes.shape({
    principalId: PropTypes.string,
  }).isRequired,
  setState: PropTypes.func.isRequired,
};

export default Step2;
