import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const GoBack = ({ prevStep, className }) => {
  const classes = useStyles();

  return (
    <button className={`${classes.button} hvr-scale`} onClick={prevStep}>
      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${classes.backIcon}`}>
        <path d="M6.83333 12.2366L1 6.73835M1 6.73835L6.83333 1.24013M1 6.73835L16 6.73835" stroke="#292A2E" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      Go back
    </button>
  );
};

GoBack.propTypes = {
  prevStep: PropTypes.func.isRequired,
};

export default GoBack;
