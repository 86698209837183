import React from 'react';

import { ExternalLinkButton } from 'components';
import { useStyles } from './styles';

const buttonsConfig = [
  {
    key: 'canister_sdk',
    title: 'Canister SDK',
    href: 'https://sdk.dfinity.org/docs/index.html',
  },
  {
    key: 'quickstart_guide',
    title: 'Quickstart Guide',
    href: 'https://sdk.dfinity.org/docs/quickstart/quickstart-intro.html',
  },
  {
    key: 'interface_spec',
    title: 'Interface Spec',
    href: 'https://sdk.dfinity.org/docs/interface-spec/index.html',
  },
  {
    key: 'dev_forum',
    title: 'Dev Forum',
    href: 'http://forum.dfinity.org/',
  },
];

const ExternalLinks = () => {
  const classes = useStyles();

  const buttons = buttonsConfig.map((buttonProps) => (
    <ExternalLinkButton
      {...buttonProps}
    />
  ));

  return (
    <div className={classes.container}>
      {buttons}
    </div>
  );
};

export default ExternalLinks;
