import React, { useState } from 'react';
import classnames from 'classnames';
import {ReactComponent as PoweredByFleekSvg} from './powered-by-fleek.svg';
import useStyles from './styles';

const PoweredByFleek = () => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);    
  };

  const onMouseLeave = () => {
    setIsHovered(false);    
  };

  return (
    <div className={classes.container}>
      <a href="https://fleek.co" target="_blank" rel="noreferrer">
        <PoweredByFleekSvg
          className={classnames(classes.logo, {
            [classes.notHovered]: !isHovered,
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </a>
    </div>
  );
};

export default PoweredByFleek;
