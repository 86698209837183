import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  title: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '36px',
    color: '#111827',
    margin: '20px 0 14px',
  },
  inputLabel: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#111827',
    margin: '0',
    marginBottom: '4px',
  },
  input: {
    marginBottom: '25px',
  },
  gradientButton: {
    marginBottom: '30px',
  },
  footer: {
    background: '#F3F4F4',
    borderRadius: '25px',
    padding: '26px 20px 30px',
    color: '#111827',
    fontSize: '16px',
  },
  footerSubtitle: {
    lineHeight: '20px',
    fontWeight: 500,
    margin: '0 0 12px',
  },
  footerDescription: {
    lineHeight: '22px',
    marginBottom: '18px',
    color: '#555659',

    '& > a': {
      textDecoration: 'none',
      color: '#555659',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  link: {
    color: '#555659',
    '&:visited': {
      color: '#555659',
    },
  },
  boldedText: {
    color: '#000000 !important',
    fontWeight: 500,
  },
});
