import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withdrawFunction } from 'config/firebase';

import { WalletOption, ExistingWallet } from './components';
import { WALLET_TYPES } from 'constants/index';
import useStyles from './styles';
import { ArrowButton, GradientButton, LoadingAnimation } from 'components';

const Step3 = ({
  nextStep,
  prevStep,
  state,
  setState,
  setCompleted,
  setChangeContent,
  setError,
}) => {
  const [loading, setLoading] = useState(false);
  const [walletSelected, setWalletSelected] = useState({
    show: false,
    finished: false,
  });
  const classes = useStyles();

  const handleClaim = () => {
    if (state.walletType === WALLET_TYPES.WALLET && !walletSelected.finished) {
      setWalletSelected({
        ...walletSelected,
        show: true,
      });
      return;
    }

    claimCycles();
  }

  const claimCycles = async (walletID = false) => {
    const payload = {
      data: {
        ...(walletID) && { cycleWalletId: walletID },
        principalId: state.principalId,
        walletType: state.walletType === WALLET_TYPES.DANK ? 'dank' : 'cycleWallet',
      },
    };

    setChangeContent('loading-state-step-3');

    setLoading(true);
    withdrawFunction(payload)
      .then((response) => {
        let walletId = '';

        if (state.walletType === WALLET_TYPES.DANK) {
          walletId = 'hk3rd-rqaaa-aaaad-qagpq-cai';
        } else {
          walletId = response.data.data.cycleWalletCanisterId;
        }

        setState({
          ...state,
          walletId,
        });
        setCompleted(true);
        nextStep();
      })
      .catch((error) => {
        setError(error.message);
        prevStep();
      });
  };

  const methodSelected = Object.values(WALLET_TYPES).includes(state.walletType);

  if (loading) {
    return (
      <div>
        <h1 className={classes.title}>
          Finishing a few things...
        </h1>
        <div className={classes.loadingContainer}>
          <LoadingAnimation />
        </div>
      </div>
    );
  }

  if (walletSelected.show) {
    return (
      <ExistingWallet
        state={state}
        setState={setState}
        walletSelected={walletSelected}
        setWalletSelected={setWalletSelected}
        claimCycles={claimCycles}
        setChangeContent={setChangeContent}
      />
    );
  }

  const setSelectedOption = (walletType) => {
    setState({
      ...state,
      walletType,
    });
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Where do you want your Cycles?</h1>
      <div className={classes.walletOptionContainer}>
        <WalletOption
          onClick={(walletType) => setSelectedOption(walletType)}
          walletType={WALLET_TYPES.WALLET}
          selected={state.walletType === WALLET_TYPES.WALLET}
          title="Cycle Wallet"
          description="A cycles wallet is a special type of canister used to hold and manage cycles."
        />
      </div>
      <div className={classes.walletOptionContainer}>
        <WalletOption
          onClick={(walletType) => setSelectedOption(walletType)}
          walletType={WALLET_TYPES.DANK}
          selected={state.walletType === WALLET_TYPES.DANK}
          title="Dank*"
          description="An open internet service that enables you to hold and use cycles with just a Principal ID."
          disabled
        />
      </div>
      <ArrowButton
        className={classes.arrowButton}
        title="Which option should I use?"
        action="Learn More"
        target="_blank"
        rel="noreferrer" 
        href="https://medium.com/@dank_ois/b9a1d3ddcebe?source=friends_link&sk=0d4c790eda6883d1c013b10cdb8f89f4"
      />
      <GradientButton
        onClick={handleClaim}
        title="Claim $20 worth of cycles"
        className={'hvr-scale'}
        disabled={!methodSelected}
      />
      <p className={classes.disclaimer}>
        *Dank is not owned or operated by DFINITY.
        <br/>
        Dank is an Open Internet Service built by Fleek.
      </p>
    </div>
  );
};

Step3.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  state: PropTypes.shape({
    walletType: PropTypes.string,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  setCompleted: PropTypes.func.isRequired,
  setChangeContent: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default Step3;
