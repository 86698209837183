import React from 'react';
import PropTypes from 'prop-types';

import Step from './components/Step';
import { useStyles } from './styles';

const Stepper = ({ steps, step }) => {
  const classes = useStyles();
  const stepComponents = steps.reduce((stepAccumulator, stepProps, index) => {
    if (!stepProps.display) return stepAccumulator;

    const addSpacer = index % 2 !== 0;

    const {
      title,
    } = stepProps;

    const currentStep = index + 1;
    const disabled = currentStep > step;
    const inactive = currentStep < step;

    const stepComponent = (
      <React.Fragment key={currentStep}>
        <Step
          title={title}
          disabled={disabled}
          step={currentStep}
          inactive={inactive}
          key={index}
          className={`${addSpacer && classes.spaced}`}
        />
      </React.Fragment>
    );

    stepAccumulator.push(stepComponent);
    return stepAccumulator;
  },[]);
  
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
    }}>
      {stepComponents}
    </div>
  );
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      dispay: PropTypes.bool,
      disabled: PropTypes.bool,
      inactive: PropTypes.bool,
      title: PropTypes.string,
    })
  ),
  step: PropTypes.number.isRequired,
};

export default Stepper;
