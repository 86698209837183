import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  input: {
    width: '100%',
    padding: '10px 13px',
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    boxSizing: 'border-box',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
  },
  containerBox: {
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    border: '1px solid #D1D5DB',
    padding: '1px',
    '&:focus-within': {
      border: '2px solid #292A2E',
      padding: '0',
    },
  },
});
