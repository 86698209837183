import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
} from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { projectAuth } from 'config/firebase';
import { PATHS, STEPS } from 'utils/stepper';
import { Stepper } from 'components';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';

const Form = forwardRef(({
  steps,
  step,
  setStep,
  state,
  setState,
  nextStep,
  prevStep,
  setCompleted,
  className,
  setChangeContent,
  setError,
  closedFaucet,
}, ref) => {
  const [user] = useAuthState(projectAuth);
  const AUTH_STEP = 1;

  useEffect(() => {
    if (!user && step !== AUTH_STEP) {
      setStep(AUTH_STEP);
    }
  }, [user, setStep, step])

  return (
    <div className={className} ref={ref}>
      <Stepper steps={STEPS} step={step}/>
      <Switch>
        <Route exact path={PATHS.auth}>
          <Step1 setState={setState} state={state} nextStep={nextStep} closedFaucet={closedFaucet}/>
        </Route>
        <Route exact path={PATHS.principalId}>
          <Step2 setState={setState} state={state} nextStep={nextStep} />
        </Route>
        <Route exact path={PATHS.deposit}>
          <Step3
            setState={setState}
            state={state}
            prevStep={prevStep}
            nextStep={nextStep}
            setCompleted={setCompleted}
            setChangeContent={setChangeContent}
            setError={setError}
          />
        </Route>
      </Switch>
    </div>
  );
});

Form.propTypes = {
  step: PropTypes.number.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  state: PropTypes.shape({
    walletType: PropTypes.string,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setCompleted: PropTypes.func.isRequired,
  setChangeContent: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  closedFaucet: PropTypes.bool.isRequired,
};

export default Form;
