import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    backgroundColor: '#F3F4F4',
    width: '100%',
    padding: '26px 20px 23px',
    borderRadius: '25px',
    boxSizing: 'border-box',
  },
  bottomHint: {
    margin: '19px 0 0',
    textAlign: 'center',
    '& > span': {
      color: '#292A2E',
      fontWeight: 500,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  titleContainer: {
    margin: '0 0 12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& a': {
      color: '#111827',
    },
    '& a:visited': {
      color: '#111827',
    },
  },
  title: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#111827',
  },
  description: {
    fontSize: '16px',
    lineHeight: '22px',
    color: '#555659',
    margin: 0,
  },
  codeboxContainer: {
    margin: '18px 0',
  },
});
