import React from 'react';
import useStyles from './styles';

const TopBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.topBar}>
      <div className={classes.logoContainer}>
        <img
          src={`${process.env.PUBLIC_URL}/dfinity-logo-vector.svg`}
          alt="dfinity-logo"
          className={classes.logo}
        />
        <img
          src={`${process.env.PUBLIC_URL}/dfinity-text-vector.svg`}
          alt="dfinity-logo"
          className={classes.dfinityText}
        />
      </div>
      <div className={classes.titleContainer}>
        <span className={classes.title}>Cycles Faucet</span>
      </div>
      <div className={classes.buttonContainer}>
        <a
          className={`${classes.button} hvr-scale`}
          href="https://dfinity.org/"
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </a>
      </div>
    </div>
  );
};

export default TopBar;
