import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const BaseCard = ({ children, childrenRef, closedFaucet }) => {
  const [cardHeight, setCardHeight] = useState(null);

  const calculateHeight = () => {
    const { clientHeight: nextHeight } = childrenRef.current;

		if (cardHeight === nextHeight) return;
		setCardHeight(nextHeight);
  };

  useEffect(() => {
    if (childrenRef !== null) calculateHeight();
  });

  const classes = useStyles({cardHeight});

  return (
    <div className={`${classes.card} ${closedFaucet && classes.closedFaucet}`}>
      <div className={classes.contentContainer}>
        {children}
      </div>
      <div className={classes.rainbowGlow} />
      <div className={classes.clearer} />
    </div>
  );
};

BaseCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  childrenRef:  PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  closedFaucet: PropTypes.bool.isRequired,
};

export default BaseCard;
