import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  cardContainer: {
    padding: '34px 36px 38px',
  },
  '@global': {
    '@font-face': [
      {
        fontFamily: 'Circular',
        src: 'url("fonts/CircularXXTT-Regular.ttf") format("ttf")',
        fontStyle: 'normal',
        fontWeight: 400,
      }
    ],
  },
});
