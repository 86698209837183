import {
  Redirect,
  Route,
} from "react-router-dom";
import LogRocket from 'logrocket';

import { PATHS } from 'utils/stepper';
import { useStickyState } from 'utils/stickyState';
import TopBar from './components/TopBar';
import FormContainer from './components/FormContainer';
import PoweredByFleek from './components/PoweredByFleek';
import Toast from './components/Toast'
import { useStyles } from  './styles';

LogRocket.init('fleek/faucet');

const closedFaucet = false;

function App() {
  console.log('Started app in', process.env.NODE_ENV);
  const [completed, setCompleted] = useStickyState(false, 'completed');
  const classes = useStyles();

  return (
    <>
      <Route exact path="/">
        <Redirect to={PATHS.auth} />
      </Route>
      <div className={classes.app}>
        <canvas id="confetti-canvas" style={{ position: 'fixed', zIndex: 1, pointerEvents: 'none' }} />
        <div className={classes.mainContent}>
          { closedFaucet && (
            <div className={classes.closedFaucetBanner}>
              <p>
                Sorry, the faucet is out of cycles. Please check back later.
              </p>
            </div>
          )}
          <TopBar />
          <FormContainer closedFaucet={closedFaucet} completed={completed} setCompleted={setCompleted} />
          <PoweredByFleek />
        </div>
        <p className={classes.supportText}>
          Having Issues? <a href="mailto:support@dfinity.org" className={classes.emailLink}>support@dfinity.org</a>
        </p>
      </div>
      <Toast />
    </>
  );
}

export default App;
