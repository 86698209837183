import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const ArrowButton = ({ title, action, target, rel, href, className }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${className}`}>
      <span className={classes.title}>
        {title}
      </span>
      <a className={classes.action} target={target} rel={rel} href={href}>
        {action}
        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.arrow}>
          <path d="M10.1667 1L16 7M16 7L10.1667 13M16 7L1 7" stroke="#292A2E" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </a>
    </div>
  );
};

ArrowButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  rel: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ArrowButton.defaultProps = {
  className: '',
};

export default ArrowButton;
