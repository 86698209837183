import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  overrides: {
    '& .Toastify__toast': {
      padding: 0,
      width: 'auto',
      minHeight: 0,
      boxShadow: 'none',
    },
    '& .Toastify__toast-body': {
      padding: 0,
      width: '100%',
      height: 'auto',
    },
    '& .Toastify__progress-bar--default': {
      background: 'rgba(0, 0, 0, 0)',
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem',
    '&.error': {
      background: '#FEF2F2',
    }
  },
  circleClose: {
    width: '16px',
    height: '16px',
  },
  description: {
    color: '#991B1B',
    order: '0',
    margin: '0px 12px',
    lineHeight: '20px',
    width: '236px',
  },
  xClose: {
    fontStyle: 'normal',
    fontSize: '20px',
    '&.error': {
      color: '#991B1B',
    }
  }
});
