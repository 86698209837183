import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { WALLET_TYPES } from 'constants/index';

import useStyles from './styles';

const WalletOption = ({
  disabled,
  selected,
  title,
  description,
  onClick,
  walletType,
  icon,
}) => {
  const classes = useStyles();

  const getIcon = () => {
    if (icon) {
      return (
        <img
          src={icon}
          alt='decoration'
          className={classnames(classes.icon, classes.dankLogo)}
        />
      )
    }

    if (walletType === WALLET_TYPES.DANK) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/dank-logo.svg`}
          alt="Cycles token logo"
          className={classnames(classes.icon, classes.dankLogo)}
        />
      );
    }

    return (
      <img
        src={`${process.env.PUBLIC_URL}/wallet.svg`}
        alt="Wallet logo"
        className={classnames(classes.icon, classes.walletLogo)}
      />
    );
  };

  return (
    <div
      onClick={() => {
        if (disabled) return;
        onClick(walletType)
      }}
      className={classnames(classes.container, {
        [classes.selected]: selected,
        [classes.unselected]: !selected,
        [classes.disabled]: disabled,
      })}
    >
      <div className={`${disabled ? classes.disabledOpacity : ''}`}>{getIcon()}</div>
      <div>
        <div className={`${classes.title} ${classes.disabledTitle}`}>
          <span className={`${disabled ? classes.disabledOpacity : ''}`}>{title}</span>
          {disabled && (
              <span className={classes.comingSoonTag}>Temporarily disabled</span>
          )}
        </div>
        <div className={`${classes.description} ${disabled ? classes.disabledOpacity : ''}`}>{description}</div>
      </div>
  </div>
  );
};

WalletOption.defaultProps = {
  icon: '',
};

WalletOption.propTypes = {
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  walletType: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default WalletOption;
