import moment from 'moment';

/**
 * IsNewGithubUser, check if use creation date as ISOString time, is less than 30 days?
 * @param {string} ISOstring 
 * @returns boolean
 */
 export const isNewGithubUser = (ISOstring) => {
  const totalDays = (Date.now() - new Date(ISOstring)) / 1000 / 60 / 60 / 24;
  return totalDays <= 90;
};

export const isActiveGithubUser = (ISOstring) => {
  const totalDays = (Date.now() - new Date(ISOstring)) / 1000 / 60 / 60 / 24;
  return totalDays <= 30;
};

const getLastContribution = async (token, username) => {
  const headers = {
    'Authorization': `bearer ${token}`,
  };

  const body = {
    "query": `query {
        viewer {
          contributionsCollection {
            endedAt
          }
        }
      }`
  };

  const response = await fetch('https://api.github.com/graphql', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  });

  const data = await response.json();
  const dateString = data.data.viewer.contributionsCollection.endedAt;

  return dateString;
}


export const isValidGithubUser = async (createdAt, updatedAt, token, username) => {
  const lastContributionString = await getLastContribution(token, username);

  // check if gh account has activity within last 30 days
  if (moment().subtract(30, "days").isAfter(moment(lastContributionString))) {
    return false;
  }

  return !isNewGithubUser(createdAt);
};
