import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const LoadingAnimation = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${className}`}>
      <div className={`${classes.ball} ${classes.yellowBall}`}/>
      <div className={`${classes.ball} ${classes.redBall}`}/>
      <div className={`${classes.ball} ${classes.blueBall}`}/>
      <div className={`${classes.ball} ${classes.magentaBall}`}/>
    </div>
  )
};

LoadingAnimation.defaultProps = {
  className: '',
};

LoadingAnimation.propTypes = {
  className: PropTypes.string,
};

export default LoadingAnimation;
