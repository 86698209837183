import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const ExternalLinkButton = ({ title, target, rel, href, className }) => {
  const classes = useStyles();

  return (
    <a
      href={href}
      rel={rel}
      target={target}
      className={`${classes.container} ${className} hvr-scale`}
    >
      {title}
    </a>
  );
};

ExternalLinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  className: PropTypes.string,
};

ExternalLinkButton.defaultProps = {
  className: '',
  target: '_blank',
  rel: 'noreferrer',
};

export default ExternalLinkButton;
