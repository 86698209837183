import React, { useState } from 'react';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import { projectAuth } from 'config/firebase';
import { isValidGithubUser } from 'utils/github';

import { toastError } from 'components/Toast';

import { GithubButton, InformationBox } from 'components/index';
import { useStyles } from './styles';

// Default texts
const description = 'Connecting to GitHub is to prevent bots and people from abusing the Cycles Faucet.';
const errorForGithubNewAccounts = 'The GitHub account must be at least 90 days old and have been active at least once in the past 30 days.'
const title = 'Why do I need to connect to GitHub?';
const defaultErrorMsgPrefix = 'Failed to login';

const Step1 = ({
  nextStep,
  closedFaucet,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    if (closedFaucet) {
      return false;
    }

    setLoading(true);

    try {
      const provider = new window.firebase.auth.GithubAuthProvider();
      const result = await projectAuth.signInWithPopup(provider);

      console.log('gh result : ', result);

      let db = window.firebase.firestore();

      const currentUser = window.firebase.auth().currentUser.uid;

      LogRocket.identify(result?.additionalUserInfo?.username, {
        name: result?.additionaluserinfo?.username,
        email: result?.user?.email,
      });

      db.collection("tokens").doc(currentUser).set({
        token: result.credential.accessToken,
      })
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch((error) => {
          console.error("Error writing document: ", error);
      });

      isValidGithubUser(
        result?.additionalUserInfo?.profile?.created_at,
        result?.additionalUserInfo?.profile?.updated_at,
        result?.credential?.accessToken,
        result?.additionalUserInfo?.username,
      )
        .then((validAccount) => {
          if (validAccount) {
            nextStep();
          } else {
            // Triggers Toast to show up
            toastError(errorForGithubNewAccounts);
            setLoading(false);
          }
        });
    } catch(e) {
      console.error('Failed to login', e);
      toastError(`${defaultErrorMsgPrefix} ${e}`);
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Connect to Github</h1>
      <GithubButton loading={loading} onClick={onClick} hover={!closedFaucet} className={classes.githubButton} />
      <InformationBox title={title} description={description} />
    </div>
  );
};

Step1.propTypes = {
  nextStep: PropTypes.func.isRequired,
  closedFaucet: PropTypes.bool.isRequired,
};

export default Step1;
