import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { GoBack } from 'components';
import { ExternalLinks, TwitterShare } from './components';
import { useStyles } from './styles';

const AllSet = forwardRef(({ prevStep, className }, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={`${className} ${classes.container}`}>
      <GoBack prevStep={prevStep} />
      <h1 className={classes.title}>
        You're all set
      </h1>
      <p className={classes.description}>
        Ready to start building? Check out these resources to help you get started.
      </p>
      <ExternalLinks />
      <TwitterShare />
    </div>
  );
});

AllSet.propTypes = {
  prevStep: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AllSet.defaultProps = {
  className: '',
};

export default AllSet;
