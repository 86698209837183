import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const InputBase = ({ className, placeholder, value, onChange, type }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.containerBox} ${className}`}>
      <input
        value={value}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        className={classes.input}
      />
    </div>
  );
};

InputBase.defaultProps = {
  placeholder: '',
  value: '',
  type: 'text',
  onChange: () => {},
};

InputBase.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default InputBase;
