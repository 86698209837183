import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
  },
  balance: {
    background: 'linear-gradient(135deg, #656566 14.34%, #0C0C0C 85.29%)',
    width: '100%',
    margin: '20px 0',
    padding: '10px 36px',
    transform: 'translateX(-36px)',
    '& p, span': {
      color: '#FFFFFF',
      margin: 0,
      padding: 0,
    },
    '& > p': {
      fontSize: '16px',
      paddingBottom: '6px',
    },
    '& > div > span': {
      fontWeight: 'bold',
      fontSize: '22px',
      '&:nth-child(2)': {
        float: 'right',        
      }
    },
  },
  identity: {
    color: '#374151',
    marginBottom: '20px',
  },
  viewLink: {
    fontWeight: 'normal',
    color: '#292A2E',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    marginRight: '6px',
    '&:hover': {
      opacity: 0.7,
    },
  },
  dankIcon: {
    marginBottom: '2px',
    marginLeft: '8px',
    width: '15px',
    height: 'auto',
  },
  idLine: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '&:nth-child(2)': {
      margin: '12px 0 0',
    },
  },
  boldId: {
    fontSize: '13px',
    fontWeight: '500',
  },
  idContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textDecoration: 'none',
    margin: '6px 0 0',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContainer: {
    '& > p': {
      color: '#374151',
      margin: 0,
      padding: 0,
    },
    '& > h1': {
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '36px',
      color: '#111827',
      margin: 0,
      padding: 0,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  partyHatImg: {
    width: '44px',
    height: '44px',
  },
  dankIDLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
