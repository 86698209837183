import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { IconError } from './icons';

import 'react-toastify/dist/ReactToastify.min.css';
import { useStyles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'

// Pseudo Enum
export const ToastNotificationType = {
  Error: "error",
};

const defaultCloseTimeout = 8000;

/**
 * MsgBox is a costum child component, specifically for use in ToastContainer
 */
const MsgBox = ({ closeToast, message, notificationType }) => {
  const classes = useStyles();
  return (
    <div className={[classes.container, notificationType].join(' ')}>
      {
        notificationType === ToastNotificationType.Error &&
        <IconError className={classes.circleClose} />
      }
      <div className={classes.description}>{ message }</div>
      <FontAwesomeIcon className={[classes.xClose, notificationType].join(' ')} icon={faTimes} onClick={closeToast} />
    </div>
  );
};

/**
 * Toast is a wrapper for ToastContainer, that provides css overrides
 * and other costumisation
 */
const Toast = () => {
  const classes = useStyles();

  return (
    <div className={classes.overrides}>
      <ToastContainer
        autoClose={defaultCloseTimeout}
        closeButton={false}
        position={toast.POSITION.BOTTOM_LEFT} />
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  notificationType: PropTypes.objectOf(PropTypes.shape(ToastNotificationType)),
};

export default Toast;

/**
 * ToastError triggers the visibility for the custom error Toast
 */
export const toastError = (message) => toast(<MsgBox message={message} notificationType={ToastNotificationType.Error} />);
