import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  '@keyframes bounce': {
    '0%, 50%, 100%': {
      transform: 'scale(0.8)',
    },
    '25%': {
      transform: 'scale(0.4)',
    },
    '75%': {
      transform: 'scale(1.0)',
    },
  },
  ball: {
    width: '2.5vw',
    height: '2.5vw',
    backgroundImage: 'linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%)',
    animation: '$bounce 1.5s 0.5s linear infinite',
    borderRadius: '100%',
  },
  blueBall: {
	 backgroundColor: '#29ABE2',
    animationDelay: '-0.2s',
  },
  yellowBall: {
    backgroundColor: '#FBB03B',
    animationDelay: '-0.4s',
  },
  redBall: {
    backgroundColor: '#ED1E79',
    animationDelay: '-0.3s',
  },
  magentaBall: {
    backgroundColor: '#522785',
    animationDelay: '-0.1s',
  },
});
