import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  button: {
    width: '100%',
    height: '44px',
    border: 'none',
    background: 'linear-gradient(94.95deg, #E0207B -1.41%, #692783 101.95%)',
    fallbacks: {
      background: '#E0207B'
    },
    borderRadius: '40px',
    fontFamily: 'Circular',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    color: '#FFFFFF',
    cursor: 'pointer'
  },
  disabledButton: {
    background: '#D9D9DA',
    cursor: 'not-allowed',
  },
});
