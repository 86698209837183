import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    textAlign: 'left',
  },
  stepTitle: {
    fontFamily: 'Circular',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    margin: '0',
  },
  enabledStepTitle: {
    color: '#292A2E',
  },
  disabledStepTitle: {
    color: '#6B7280',
  },
  details: {
    fontFamily: 'Circular',
    fontSize: '14px',
    fontWeight: 500,
    margin: '0',
    color: '#111827',
    lineHeight: '20px',
  },
  progressContainer: {
     marginTop: '16px',
    position: 'relative',
    width: '121px',
  },
  progressGray: {
    width: '100%',
    height: '4px',
    position: 'absolute',
    bottom: 0,
    background: '#E5E7EB',
  },
  progressColor: {
    height: '4px',
    position: 'absolute',
    bottom: 0,
    zIndex: 10,
    backgroundColor: '#292A2E',
    transition: 'width 0.5s ease-in-out',
  },
  inactive: {
    opacity: '50%',
  },
});
