import React, { useState, useEffect } from 'react';
import { InputBase, GradientButton, GoBack } from 'components';
import { WALLET_TYPES } from 'constants/index';

import existingWalletIcon from './existingWalletIcon.svg';
import newWalletIcon from './newWalletIcon.svg';

import WalletOption from '../WalletOption';

import useStyles from './styles';
const ExistingWallet = ({
  state,
  setState,
  walletSelected,
  setWalletSelected,
  claimCycles,
  setChangeContent,
}) => {
  const [internalStep, setInternalStep] = useState(1);
  const classes = useStyles();

  useEffect(() => {
    setChangeContent(`internal-step-${internalStep}`);
  }, [internalStep, setChangeContent]);

  const onInputChange = (e) => {
    setWalletSelected({
      ...walletSelected,
      selectedWallet: e.target.value,
    });
  };

  if (internalStep === 2) {
    return (
      <div className={classes.container}>
        <div className={classes.topRow}>
          <GoBack prevStep={() => setInternalStep(1)} />
        </div>
        <h1 className={classes.title}>
          Enter Cycles Wallet ID
        </h1>
        <h2 className={classes.inputLabel}>
          Cycles Wallet ID
        </h2>
        <InputBase onChange={onInputChange} value={walletSelected.selectedWallet} className={classes.input}/>
        <GradientButton
          disabled={!walletSelected.selectedWallet}
          onClick={() => {
            setWalletSelected({
              ...walletSelected,
              finished: true,
              show: false,
            });
            claimCycles(walletSelected.selectedWallet);
          }}
          title="Continue"
          className={'hvr-scale'}
        />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <GoBack prevStep={() => {
          setChangeContent('go-back-select');
          setWalletSelected({
            show: false,
          });
        }} />
      </div>
      <h1 className={classes.title}>
        Where do you want your Cycles?
      </h1>
      <div className={classes.walletOptionContainer}>
        <WalletOption
          onClick={(walletType) => setWalletSelected({
            ...walletSelected,
            existingWallet: true,
          })}
          walletType={WALLET_TYPES.WALLET}
          selected={walletSelected.existingWallet === true}
          title="Use Existing Cycles Wallet"
          description="Have a Cycles Wallet already? Receive your cycles to that Wallet ID."
          icon={existingWalletIcon}
        />
      </div>
      <div className={classes.walletOptionContainer}>
        <WalletOption
          onClick={(walletType) => {
            setWalletSelected({
              ...walletSelected,
              existingWallet: false,
            });
          }}
          walletType={WALLET_TYPES.WALLET}
          selected={walletSelected.existingWallet === false}
          title="Generate New Cycles Wallet"
          description="Create a new cycles wallet and receive your cycles on that Wallet ID."
          icon={newWalletIcon}
        />
      </div>
      <GradientButton
        onClick={() => {
          if (walletSelected.existingWallet) {
            setInternalStep(2)
          } else {
            setWalletSelected({
              finished: true,
              show: false,
              ...walletSelected,
            });
            claimCycles();
          }
        }}
        title="Continue"
        className={`hvr-scale ${classes.continueButton}`}
        disabled={walletSelected.existingWallet === undefined}
      />
    </div>
  );
};

export default ExistingWallet;
