import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  card: {
    position: 'relative',
    background: '#FFFFFF',
    boxShadow: '0px 2px 74px rgba(0, 0, 0, 0.08)',
    borderRadius: '25px',
    width: '500px',
    margin: 'auto',
    boxSizing: 'border-box',
  },
  contentContainer: {
    float: 'left',
    height: props => `${props.cardHeight}px`,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 74px 0px #00000014',
    transition: 'height 1s ease',
    overflow: 'hidden',
    borderRadius: '25px',
  },
  clearer: {
    clear: 'both',
  },
  closedFaucet: {
    opacity: '50%',
  },
});
