import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  title: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '36px',
    color: '#111827',
    margin: '12px 0 0',
  },
  description: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#374151',
    margin: '0 0 22px',
  },
  container: {
    backgroundColor: '#FFFFFF',
  },
});
