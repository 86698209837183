import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const GradientButton = ({ className, title, onClick, disabled, }) => {
  const classes = useStyles();

  return (
    <button
      className={`${classes.button} ${disabled && classes.disabledButton} ${className}`}
      onClick={onClick}
      disabled={disabled}>
      {title}
    </button>
  );
};

GradientButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => {},
};

GradientButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default GradientButton;
