import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';

import { useStyles } from './styles';

const GithubButton = ({ hover, loading, onClick, className = '' }) => {
  const classes = useStyles();

  const handleClick = (e) => {
    if (!loading) {
      onClick(e);
    }
  }

  return (
    <button className={`${classes.button} ${!hover && classes.noHover} ${loading && classes.disabledButton} ${className} hvr-scale`} onClick={handleClick}>
      {
        loading ?
          <FontAwesomeIcon icon={faSpinnerThird} className={`${classes.spinner} fa-spin`} /> :
          <FontAwesomeIcon icon={faGithub} className={classes.githubIcon}/>
      }
      <span className={classes.text}>
        {
          loading ?
            'Connecting...' :
            'Connect to Github'
        }
      </span>
    </button>
  );
}

GithubButton.defaultProps = {
  loading: false,
  onClick: () => {},
  hover: true,
};

GithubButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  hover: PropTypes.bool,
};

export default GithubButton;
