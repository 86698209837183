import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  spinner: {
    fontSize: '23px',
    '--fa-primary-color': '#FFFFFF',
    '--fa-secondary-color': '#414652',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#111827',
    border: 'none',
    borderRadius: '10px',
    outline: 'none',
    cursor: 'pointer',
  },
  disabledButton: {
    cursor: 'not-allowed',
  },
  text: {
    fontFamily: 'Circular',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginLeft: '6px',
  },
  githubIcon: {
    fontSize: '23px',
    color: '#FFFFFF',
  },
  noHover: {
    cursor: 'default',
    '&:hover': {
      transform: 'scaleX(1)',
    }
  }
});
