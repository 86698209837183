import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';

import { useStyles } from './styles';

const InformationBox = ({ title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <FontAwesomeIcon className={classes.infoIcon} icon={faQuestionCircle} />
        <span className={classes.title}>
          {title}
        </span>
      </div>
      <span className={classes.description}>
        {description}
      </span>
    </div>
  );
};

InformationBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default InformationBox;
