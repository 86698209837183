import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '10px',
    background: 'rgba(231, 232, 233, 0.5)',
    padding: '21px 23px',
    textAlign: 'left',
  },
  titleContainer: {
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '12px',
  },
  infoIcon: {
    fontStyle: 'bold',
    color: '#292A2E',
    marginRight: '8px',
  },
  title: {
    fontFamily: 'Circular',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#111827',
  },
  description: {
    fontFamily: 'Circular',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#374151',
  },
});
