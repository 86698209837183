import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Circular',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#292A2E',
    border: '2px solid #292A2E',
    borderRadius: '40px',
    padding: '12px 15px',
    textDecoration: 'none',
    boxSizing: 'border-box',
  },
  icon: {
    height: '16px',
  },
});
