import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';

import { projectAuth } from 'config/firebase';
import { BaseCard } from 'components';
import ConfettiGenerator from "confetti-js";
import { PATHS, useStepper } from 'utils/stepper';
import { useStickyState } from 'utils/stickyState';
import ToastComponent, { toastError } from 'components/Toast';

import { useStyles } from './styles';
import  { Congratulations, AllSet, Form } from './components';

const defaultState = {
  principalId: '',
  walletId: '',
  walletType: 'CRYPTO',
  email: '',
  githubId: '',
};

const redirectErrors = ['Github account has already withdrawn cycles.', 'Principal has already withdrawn cycles.'];

const FormContainer = ({ completed, setCompleted, closedFaucet }) => {
  const history = useHistory();
  const [areConfettisTriggered, setAreConfettisTriggered] = useState(false);
  const [updatedStepper, setUpdatedStepper] = useState(false);
  const [ ,userLoading] = useAuthState(projectAuth);
  const { step, path, nextStep, prevStep, setStep } = useStepper(history);
  const [ locationKeys, setLocationKeys ] = useState([])

  const [state, setState] = useStickyState(defaultState, 'userInfuserInfoo');
  const [correctStep, setCorrectStep] = useStickyState(1, 'correctStep');
  const CONGRATULATIONS_STEP = 4;
  
  const [error, setError] = useState(false);
  const [changeContent, setChangeContent] = useState(null);
  const [childrenRef, setChildrenRef] = useState(null);

  useEffect(() => {
    if (error) {
      // Handle redirect
      if (redirectErrors.includes(error)) {
        setTimeout(() => {
          window.location.href = 'https://dfinity.org/developers/';
        }, 4000);
      }

      // Clear Error
      setTimeout(() => {
        setError(false)
      }, 8000) ;

      // Show Error
      toastError(error);
    }
  }, [error]);

  useEffect(() => {
    const ref = createRef();
    setChildrenRef(ref);
  }, [step, changeContent]);

  const classes = useStyles();

  useEffect(() => {
    history.push(path);
  }, [path, history]);

  useEffect(() => {
    if (completed && !updatedStepper) {
      setUpdatedStepper(true);
      setStep(CONGRATULATIONS_STEP);
    }
  }, [completed, setStep, setUpdatedStepper, updatedStepper]);

  useEffect(() => {
    if (correctStep === CONGRATULATIONS_STEP && !areConfettisTriggered) {
      const confettiElement = document.getElementById('confetti-canvas');
      const confettiSettings = {
        target: confettiElement,
        start_from_edge: true,
        rotate: true,
        respawn: false,
        max: 200,
      };
      const confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      setAreConfettisTriggered(true);
    }
  }, [correctStep, areConfettisTriggered]);

  useEffect(() => {
    if (step !== correctStep) {
      setStep(correctStep);
    }
  }, [correctStep, step, setStep]);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys);
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ]);
          setCorrectStep(step - 1);
          prevStep();
        }
      }
    })
  }, [locationKeys, history, prevStep, setCorrectStep, step]);

  useEffect(() => {
    if (closedFaucet && correctStep !== 1 && step !== 1) {
      setCorrectStep(1);
      setStep(1);
    }
  }, [closedFaucet, setCorrectStep, setStep, correctStep, step]);

  if (userLoading) {
    return null;
  }

  const handleNextStep = () => {
    setCorrectStep(step + 1);
    nextStep();
  };

  const handlePrevStep = () => {
   setCorrectStep(step - 1);
    prevStep();
  };

  const handleSetStep = (newStep) => {
    setCorrectStep(newStep);
    setStep(newStep);
  };

  return (
    <BaseCard childrenRef={childrenRef} closedFaucet={closedFaucet}>
      <Switch>
        <Route path={PATHS.congratulations}>
          <Congratulations
            state={state}
            setState={setState}
            nextStep={handleNextStep}
            ref={childrenRef}
            className={classes.cardContainer}
          />
        </Route>
        <Route path={PATHS.allSet}>
          <AllSet
            prevStep={handlePrevStep}
            ref={childrenRef}
            className={classes.cardContainer}
          />
        </Route>
        <Route>
          <Form
            step={step}
            state={state}
            setState={setState}
            nextStep={handleNextStep}
            prevStep={handlePrevStep}
            setStep={handleSetStep}
            setCompleted={setCompleted}
            className={classes.cardContainer}
            ref={childrenRef}
            setChangeContent={setChangeContent}
            setError={setError}
            closedFaucet={closedFaucet}
          />
        </Route>
      </Switch>
      <div style={{ width: '428px' }}>
        <ToastComponent />
      </div>
    </BaseCard>
  );
};

FormContainer.propTypes = {
  completed: PropTypes.bool.isRequired,
  setCompleted: PropTypes.func.isRequired,
};

export default FormContainer;
