import React from 'react';
import PropTypes from 'prop-types';
import CodeBox from 'components/CodeBox';
import { WALLET_TYPES } from 'constants/index';

import { useStyles } from './styles';

const getDFXCommand = (walletId) => `dfx identity --network ic set-wallet --force ${walletId}`;

const DfxCommand = ({
  state,
  nextStep,
}) => {
  const classes = useStyles();
  const walletId = state.walletType === WALLET_TYPES.DANK ? 'aanaa-xaaaa-aaaah-aaeiq-cai' : state.walletId;

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <h3 className={classes.title}>What's next?</h3>
        { state.walletType === WALLET_TYPES.DANK && (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.dank.ooo/xtc/getting-started/"
          >
            Documentation
          </a>
        )}
      </div>
      <p className={classes.description}>
        Run the command below in the DFX CLI tool to set your default wallet
        {
          state.walletType === WALLET_TYPES.DANK ? ' to the Cycles Token (XTC) ID' : ''
        }.
      </p>
      <div className={classes.codeboxContainer}>
        <CodeBox code={getDFXCommand(walletId)} />
      </div>
      <div className={classes.bottomHint}>
        Done Running the Command? <span onClick={nextStep}>Click Here</span>
      </div>
    </div>
  );
};

DfxCommand.propTypes = {
  state: PropTypes.shape({
    walledId: PropTypes.string,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default DfxCommand;
