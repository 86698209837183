import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    outline: 'none',
    padding: '19px 15px',
    background: '#F3F4F4',
    borderRadius: '15px',
    width: '100%',
    fontFamily: 'Circular',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
    border: 'none',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
    color: '#292A2E',
  },
  action: {
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    color: '#292A2E',
  },
  arrow: {
    marginLeft: '5px',
    fill: '#292A2E',
  }
});

