import { useReducer } from 'react';

export const actionTypes = {
  NEXT_STEP: 'NEXT_STEP',
  PREV_STEP: 'PREV_STEP',
  SET_STEP: 'SET_STEP',
};

export const PATHS = {
  auth: '/auth',
  principalId: '/principal-id',
  deposit: '/deposit',
  congratulations: '/congratulations',
  allSet: '/all-set',
};

export const STEPS = [
  {
    title: 'GitHub Auth',
    path: PATHS.auth,
    display: true,
  },
  {
    title: 'Principal ID',
    path: PATHS.principalId,
    display: true,
  },
  {
    title: 'Deposit Method',
    path: PATHS.deposit,
    display: true,
  },
  {
    path: PATHS.congratulations,
    display: false,
  },
  {
    path: PATHS.allSet,
    display: false,
  },
];


const stepperReducer = (state, action) => {
  switch(action.type) {
    case actionTypes.NEXT_STEP: {
      const index = state.index + 1;

      return {
        step: state.step + 1,
        index, 
        path: STEPS[index].path,
      };
    }
    case actionTypes.PREV_STEP: {
      const index = state.index - 1;

      return {
        step: state.step - 1,
        index,
        path: STEPS[index].path,
      };
    }
    case actionTypes.SET_STEP: {
      const index = action.step - 1;

      return {
        step: action.step,
        index: action.step - 1,
        path: STEPS[index].path,
      };
    }
    default: {
      throw new Error(`Unhandled type ${action.type}`);
    }
  }
};

export const useStepper = (history) => {
  const { pathname: path } = history.location;
  const indexFromPath = STEPS.findIndex((step) => step.path === path);
  const startingIndex = indexFromPath === -1 ? 0 : indexFromPath;
  const [step, dispatch] = useReducer(stepperReducer, { step: startingIndex + 1, index: startingIndex });
  
  const nextStep = () => dispatch({ type: actionTypes.NEXT_STEP });
  const prevStep = () => dispatch({ type: actionTypes.PREV_STEP });
  const setStep = (newStep) => dispatch({ type: actionTypes.SET_STEP, step: newStep });

  return { step: step.step, path: step.path, nextStep, prevStep, setStep }
};
