import { createUseStyles } from 'react-jss'

const styles = createUseStyles({
  walletOptionContainer: {
    marginBottom: '15px',
    height: '100px',
  },
  title: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '36px',
    color: '#111827',
    margin: '20px 0 14px',
  },
  arrowButton: {
    margin: '10px 0 25px',
  },
  disclaimer: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#374151',
    margin: '25px 0 0',
  },
  loadingContainer: {
    marginTop: '25px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default styles;
