import { createUseStyles } from 'react-jss'

const styles = createUseStyles({
  title: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '36px',
    color: '#111827',
    margin: '14px 0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  walletOptionContainer: {
    marginBottom: '15px',
    height: '100px',
  },
  inputLabel: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#111827',
    margin: '0',
    marginBottom: '4px',
  },
  input: {
    marginBottom: '25px',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
  continueButton: {
    marginTop: '10px',
  },
});

export default styles;
