import { createUseStyles } from 'react-jss'

const styles = createUseStyles({
  container: {
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  selected: {
    border: '2px solid #292A2E',
    boxShadow: '0px 0px 0px rgba(6, 44, 82, 0.1), 0px 1px 3px rgba(64, 66, 69, 0.12), 0px 2px 16px rgba(33, 43, 54, 0.08)',
    padding: '17px 16px 19px 20px',
  },
  unselected: {
    border: '1px solid #D1D5DB',
    boxShadow: '0px 0px 0px rgba(6, 44, 82, 0.1)',
    padding: '18px 15px 19px 21px',
    '&:hover': {
      border: '2px solid #A0A3AB',
      padding: '17px 16px 19px 20px',
    },
  },
  title: {
    fontFamily: 'Circular',
    lineHeight: '20px',
    marginBottom: '4px',
    fontSize: '16px',
    fontWeight: 500,
  },
  description: {
    fontFamily: 'Circular',
    lineHeight: '18px',
    fontSize: '14px',
  },
  icon: {
    marginRight: '15px',
  },
  dankLogo: {
    width: '37px',
  },
  waletLogo: {
    width: '34px',
  },
  disabled: {
    border: '1px solid #D1D5DB',
    justifyContent: 'start',
    cursor: 'not-allowed',
    '&:hover': {
      border: '1px solid #D1D5DB',
      padding: '18px 15px 19px 21px',
    }
  },
  disabledOpacity: {
    opacity: 0.6,
  },
  comingSoonTag: {
    marginTop: '-3px',
    fontSize: '14px',
    background: 'gainsboro',
    padding: '2px 8px',
    borderRadius: '5px',
  },
  disabledTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
});

export default styles;
