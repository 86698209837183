import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  container: {
    borderRadius: '10px',
    backgroundColor: '#292A2E',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '7px 11px 16px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  circleDecorations: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& div': {
      width: '11px',
      height: '11px',
      borderRadius: '100%',
      marginRight: '6px',
    }
  },
  redDecoration: {
    backgroundColor: '#FF5F56',
  },
  yellowDecoration: {
    backgroundColor: '#FFBD2E',
  },
  greenDecoration: {
    backgroundColor: '#27C93F',
  },
  copyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#FFFFFF',
    cursor: 'pointer',
    marginTop: '4px',
  },
  copyIcon: {
    fontSize: '14px',
    marginRight: '6px',
  },
  copyText: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  codeContainer: {
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    marginTop: '11px',
  },
  '@keyframes copied': {
    '0% 100%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
  },
  copiedAnimation: {
    animationName: '$copied',
    animationDuration: '0.5s',
  },
});
