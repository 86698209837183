const firebaseConfig = {
  development: {
    apiKey: "AIzaSyCQZxnSm9kq-K03yh1jemqbRX8X34sDjdk",
    authDomain: "dfinity-faucet-dev.firebaseapp.com",
    projectId: "dfinity-faucet-dev",
    storageBucket: "dfinity-faucet-dev.appspot.com",
    messagingSenderId: "865833390413",
    appId: "1:865833390413:web:adcc93a38ca9a119c56704"
  },
  production: {
    apiKey: "AIzaSyD5bcBfIZP44F1ITOOvI1zjqAIqiHU5ADo",
    authDomain: "dfinity-faucet.firebaseapp.com",
    projectId: "dfinity-faucet",
    storageBucket: "dfinity-faucet.appspot.com",
    messagingSenderId: "182663061476",
    appId: "1:182663061476:web:82ce98a741ba52192f71f1",
    measurementId: "G-7XCW1J58T5"
  }
}

const config = firebaseConfig[process.env.REACT_APP_DFINITY_FAUCET_ENV] || firebaseConfig.development

window.firebase.initializeApp(config);

export const projectFirestore = window.firebase.firestore();
export const projectAuth = window.firebase.auth();
export const withdrawFunction = window.firebase.app().functions('us-central1').httpsCallable('withdraw');
