import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';

import { useStyles } from './styles';
import RedHeart from './redHeart.svg';

const TWITTER_SHARE_CONFIG = {
  href: 'https://twitter.com/intent/tweet?text=Just%20claimed%20$20%20worth%20of%20cycles%20in%20the%20Cycles%20Faucet%20to%20get%20started%20developing%20canisters%20on%20@Dfinity%27s%20%23InternetComputer%20🔥',
  target: '_blank',
  rel: 'noreferrer',
};

const TwitterShare = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.container}>
      <h3 className={classes.title}>
        Share the love <img src={RedHeart} alt="redHeart" className={classes.redHeart} />
      </h3>
      <p className={classes.description}>
        Let your dev friends know to claim $20 in cycles and start building on the Internet Computer!
      </p>
      <a
        className={`${classes.twitterButton} hvr-scale`}
        {...TWITTER_SHARE_CONFIG}
      >
        <FontAwesomeIcon icon={faTwitter} className={classes.twitterLogo} />
        Share on Twitter
      </a>
    </div>
  );
};

export default TwitterShare;
