import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  button: {
    border: 'none',
    background: 'inherit',
    cursor: 'pointer',
    outline: 'none',
    color: '#111827',
    fontFamily: 'Circular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
  },
  backIcon: {
    marginRight: '9px',
  },
});
