import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 135px',
    backgroundColor: '#ffffff',
    marginBottom: '55px',
    transition: 'all 1s ease',
    '@media (max-width: 830px)': {
      padding: '13px 55px',
    },
    '@media (max-width: 640px)': {
      padding: '13px 25px',
    },
    '@media (max-width: 475px)': {
      padding: '13px 10px',
    },
  },
  logoContainer: {
    display: 'flex',
    width: '33.3%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    width: '33.3%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    width: '33.3%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logo: {
    height: '26px',
  },
  dfinityText: {
    marginLeft: '7px',
    '@media (max-width: 474px)': {
      display: 'none',
    },
  },
  title: {
    color: '#292A2',
    fontSize: 16,
    fontFamily: 'Circular',
  },
  button: {
    height: 32,
    borderRadius: '300px',
    border: '1px solid #000000',
    backgroundColor: '#ffffff',
    fontSize: 16,
    fontFamily: 'Circular',
    fontWeight: 500,
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 14px',
    color: '#000000',
    textDecoration: 'none',
  },
});

export default useStyles;
