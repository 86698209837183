import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DfxCommand from './components/DfxCommand';
import { WALLET_TYPES } from 'constants/index';
import { useStyles } from './styles';
import congratsPopper from 'images/congrats-popper.png';

const Congratulations = forwardRef(({
  state,
  nextStep,
  className,
}, ref) => {
  const classes = useStyles();

  const getWalletId = () => {
    if (state.walletType === WALLET_TYPES.DANK) {
      return (
        <div className={classes.idLine}>
          <div className={classes.dankIDLine}>
            <span>
              Cycles Token (XTC) ID
            </span>
            <img
              src={`${process.env.PUBLIC_URL}/xtc-logo.svg`}
              alt="Dank logo"
              className={classes.dankIcon}
            />
          </div>
          <div className={classes.idContainer}>
            <span className={classes.boldId}>
              aanaa-xaaaa-aaaah-aaeiq-cai
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.idLine}>
        <span>Wallet ID</span>
        <span className={classes.boldId}>{state.walletId}</span>
      </div>
    );
  };

  return (
    <div className={className} ref={ref}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.textContainer}>
            <h1>Congrats!</h1>
            <p>You claimed $20 worth of cycles.</p>
          </div>
          <img className={classes.partyHatImg} src={congratsPopper} alt="Congratulations party popper!" />
        </div>
        <div className={classes.balance}>
          <p>Balance</p>
          <div>
            <span>
              15 {state.walletType === WALLET_TYPES.DANK ? 'XTC' : 'TC'}
            </span>
            <span>
              $20.00
            </span>
          </div>
        </div>
        <div className={classes.identity}>
          <div className={classes.idLine}>
            <span>Principal ID</span>
            <span className={classes.boldId}>
              {state.principalId}
            </span>
          </div>
          {getWalletId()}
          { state.walletType === WALLET_TYPES.CRYPTO && (
              <a href="https://fleek.co" target="_blank" rel="noreferrer" className={classes.viewLinkContainer}>
                <span className={classes.viewLink}>
                  View on IC
                </span>
                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.1667 1L16 7M16 7L10.1667 13M16 7L1 7" stroke="#292A2E" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </a>
          )}
        </div>
        <DfxCommand state={state} nextStep={nextStep}/>
      </div>
    </div>
  );
});

Congratulations.propTypes = {
  state: PropTypes.shape({
    walletType: PropTypes.string,
    principalId: PropTypes.string,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default Congratulations;
